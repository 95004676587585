import { useEffect } from 'react';
import { format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import type { DateValue } from '@internationalized/date';
import { parseAbsoluteToLocal } from '@internationalized/date';
import { addToast, PhoneNumber } from '@sezane/front-components';
import { useMutation } from '@tanstack/react-query';

import Button from '@components/Button/Button';
import DateField from '@components/Form/DateField';
import FieldErrorMessage from '@components/Form/FieldErrorMessage';
import { TextField } from '@components/Form/TextField';
import { useAppContext } from '@context/AppContext';
import { useAuth } from '@context/AuthContext';
import { useCountry } from '@context/CountryContext';
import apiOms from '@utils/apiOms';
import { userInfo } from '@utils/validation/user';
import type { User } from 'types/apiOms';

import '@components/Form/checkbox.css';

const calendarToISOStringwithTZ = (calendarObject?: DateValue) => {
    if (!calendarObject) {
        return null;
    }

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return format(calendarObject.toDate(userTimeZone), "yyyy-MM-dd'T'HH:mm:ss'-00:00'");
};

const initBirthdate = (birthDate?: User['birthDate']) => {
    if (!birthDate) {
        return null;
    }

    const localDate = parseAbsoluteToLocal(birthDate);
    const localOffset = localDate.offset / 1000 / 60;

    return localDate.add({ minutes: -localOffset });
};

defineMessages({
    yes: { id: 'commons.yes' },
    no: { id: 'commons.no' },
});

const formatDefaultValues = (user?: User) => ({
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    birthDate: initBirthdate(user?.birthDate) as DateValue,
    phone: user?.phone ?? '',
});

const MyInformations = () => {
    const { user } = useAuth();
    const { country } = useCountry();
    const { brandCode } = useAppContext();
    const intl = useIntl();

    const {
        formState: { errors, isDirty },
        handleSubmit,
        control,
        reset,
        setError,
    } = useForm({
        defaultValues: formatDefaultValues(user),
        resolver: yupResolver(userInfo),
    });

    const { mutateAsync: updateUser, isLoading: isLoadingUpdateUser } = useMutation(
        (dataToUpdate: User) => apiOms.omsAuthenticated.updateUser({ brandCode }, dataToUpdate),
        {
            onSuccess: updatedUser => {
                reset(formatDefaultValues(updatedUser.data), { keepValues: true });
                addToast(intl.formatMessage({ id: 'informations.form.update.success' }), 'success');
            },
            onError: ({ error }) => {
                error['invalid-params'].map((param: any) =>
                    setError(param.name, { type: error.type, message: param.reason }, { shouldFocus: true })
                );
            },
        }
    );

    const onSubmit = <
        T extends Omit<User, 'birthDate'> & {
            birthDate?: DateValue;
        }
    >(
        data: T
    ) => {
        const dataToUpdate: User = {
            ...user,
            ...data,
            birthDate:
                data.birthDate && typeof data.birthDate !== 'string' ? calendarToISOStringwithTZ(data.birthDate) : null,
        };
        return updateUser(dataToUpdate);
    };

    return user ? (
        <form
            className="my-10 grid gap-[1.6rem] border-t border-gray-lighter pt-10 mobile:grid-cols-1 desktop:grid-cols-2"
            onSubmit={handleSubmit(onSubmit)}
        >
            <Controller
                control={control}
                name="firstName"
                render={({ field }) => (
                    <TextField
                        {...field}
                        isRequired
                        label={<FormattedMessage id="informations.form.firstName" />}
                        errorMessage={errors[field.name] && <FieldErrorMessage name={field.name} errors={errors} />}
                    />
                )}
            />

            <Controller
                control={control}
                name="lastName"
                render={({ field }) => (
                    <TextField
                        {...field}
                        isRequired
                        label={<FormattedMessage id="informations.form.lastName" />}
                        errorMessage={errors[field.name] && <FieldErrorMessage name={field.name} errors={errors} />}
                    />
                )}
            />
            <Controller
                control={control}
                name="birthDate"
                render={({ field }) => (
                    <DateField
                        {...field}
                        granularity="day"
                        label={intl.formatMessage({ id: 'informations.form.birthdate' })}
                        errorMessage={errors[field.name] && <FieldErrorMessage name={field.name} errors={errors} />}
                        inputClassName="text-lg"
                    />
                )}
            />
            <div className="desktop:col-span-1">
                <Controller
                    control={control}
                    name="phone"
                    render={({ field }) => (
                        <PhoneNumber
                            {...field}
                            initialValue={user.phone ?? ''}
                            country={country}
                            className="h-[6.3rem]"
                            fontStyles="font-account-heading uppercase font-medium text-lg tracking-wider"
                        />
                    )}
                />
                {errors.phone ? (
                    <div className="font-account-body text-xs font-medium text-error before:empty:inline-block before:empty:content-['']">
                        <FieldErrorMessage errors={errors} name="phone" />
                    </div>
                ) : null}
            </div>
            <Button
                disabled={!isDirty}
                type="submit"
                className="mt-2 desktop:w-[28rem]"
                isLoading={isLoadingUpdateUser}
            >
                <FormattedMessage id="informations.validate" />
            </Button>
        </form>
    ) : null;
};

export default MyInformations;
