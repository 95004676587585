import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Dialog, Radio, RadioGroup } from '@sezane/front-components';

import Button from '@components/Button/Button';
import { useAppContext } from '@context/AppContext';
import { useAuth } from '@context/AuthContext';
import apiOms from '@utils/apiOms';
import type { Notifications } from 'types/apiOms';

const consentMessages = defineMessages({
    only_email: { id: 'newsletter_signup.only_email' },
    yes_others: { id: 'newsletter_signup.yes_others' },
});

type FormValues = { consent: 'yes' | 'no' | 'only-email' };

const NewsletterSignup = () => {
    const { user } = useAuth();
    const { brandCode, locale, site } = useAppContext();
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const media = {
        sezane: [
            'https://media.sezane.com/v1723125123/website/assets/visuels/socialsignup.jpg',
            'https://media.sezane.com/image/upload/c_thumb,g_auto,h_240,w_650,z_3/v1723125123/website/assets/visuels/socialsignup.jpg',
            'https://media.sezane.com/image/upload/w_1000/v1723125123/website/assets/visuels/socialsignup.jpg',
        ],
        octobre: [
            'https://media.octobre-editions.com/image/upload/v1728565100/website/assets/visuels/socialsignup.jpg',
            'https://media.octobre-editions.com/image/upload/c_thumb,g_auto,h_240,w_650,z_3/v1728565100/website/assets/visuels/socialsignup.jpg',
            'https://media.octobre-editions.com/image/upload/w_1000/v1728565100/website/assets/visuels/socialsignup.jpg',
        ],
    };
    const {
        site: { code },
    } = useAppContext();
    const hasSMSConsent = code === 'us';
    const {
        handleSubmit,
        formState: { isSubmitting },
        setValue,
    } = useForm<FormValues>({ defaultValues: { consent: hasSMSConsent ? 'yes' : 'only-email' } });

    const onClose = () => {
        setOpen(false);
    };

    const onSave = async (data: FormValues) => {
        let notifications: Notifications;

        if (data.consent === 'yes') {
            notifications = {
                newsletter: [{ marketingBrandCode: brandCode, status: true }],
                sms: [{ marketingBrandCode: brandCode, status: true }],
            };
        } else if (data.consent === 'only-email') {
            notifications = {
                newsletter: [{ marketingBrandCode: brandCode, status: true }],
            };
        } else {
            notifications = {
                newsletter: [{ marketingBrandCode: brandCode, status: false }],
                sms: [{ marketingBrandCode: brandCode, status: false }],
            };
        }

        await apiOms.omsAuthenticated.notificationsUpdate(
            {
                brandCode,
                localeCode: locale,
                siteCode: site.code!,
            },
            notifications
        );

        onClose();
    };

    useEffect(() => {
        if (user?.subscribedToInvitationsEmails === null) {
            setOpen(true);
        }
    }, [user]);

    return (
        open && (
            <Dialog mandatory={true} open={true} onClose={onClose} panelClassName="max-w-[100rem] p-0">
                <div className="flex w-full flex-wrap bg-white">
                    <div className="w-1/2 mobile:w-full">
                        <img
                            src={media[brandCode][0]}
                            srcSet={`${media[brandCode][1]} 200w, ${media[brandCode][2]} 1000w`}
                            sizes="(max-width: 767px) 200px, 1000px"
                            alt="Newsletter Signup"
                            className="h-full w-full object-cover object-top mobile:max-h-[24rem]"
                        />
                    </div>
                    <form
                        className="relative flex w-1/2 flex-col justify-center px-8 mobile:w-full"
                        onSubmit={handleSubmit(onSave)}
                    >
                        <div className="space-y-16 px-6">
                            <h2
                                className="mobile:text-[4rem]font-medium mb-4  text-center  text-[5rem] uppercase leading-none"
                                dangerouslySetInnerHTML={{
                                    __html: intl.formatMessage({ id: 'newsletter_signup.title' }),
                                }}
                            />
                            <label htmlFor="newsletter" className="block text-center text-xl text-gray-medium">
                                <FormattedMessage id="newsletter_signup.newsletter.description" />
                            </label>
                            <div className="space-y-4">
                                <div className="flex flex-row justify-center">
                                    <RadioGroup
                                        name="newsletter"
                                        defaultValue={hasSMSConsent ? 'yes' : 'only-email'}
                                        direction="horizontal"
                                        className="flex flex-col"
                                        id="newsletter"
                                        onChange={value => setValue('consent', value as FormValues['consent'])}
                                    >
                                        {hasSMSConsent && (
                                            <Radio
                                                value="yes"
                                                className="font-account-heading font-medium tracking-normal !text-black"
                                            >
                                                <FormattedMessage id="newsletter_signup.yes_us" />
                                            </Radio>
                                        )}
                                        <Radio
                                            value="only-email"
                                            className="font-account-heading font-medium tracking-normal !text-black"
                                        >
                                            <FormattedMessage
                                                id={
                                                    hasSMSConsent
                                                        ? consentMessages.only_email.id
                                                        : consentMessages.yes_others.id
                                                }
                                            />
                                        </Radio>
                                        <Radio
                                            value="no"
                                            className="font-account-heading font-medium tracking-normal !text-black"
                                        >
                                            <FormattedMessage id="newsletter_signup.no" />
                                        </Radio>
                                    </RadioGroup>
                                </div>
                            </div>
                            <Button className=" w-full" type="submit" isLoading={isSubmitting}>
                                <FormattedMessage id="newsletter_signup.submit" />
                            </Button>
                        </div>
                    </form>
                </div>
            </Dialog>
        )
    );
};

export default NewsletterSignup;
