import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { addToast, BooleanRadio, Collapse } from '@sezane/front-components';
import { useMutation, useQuery } from '@tanstack/react-query';

import Button from '@components/Button/Button';
import { SecondTitle } from '@components/commons';
import { useAppContext } from '@context/AppContext';
import { useUrl } from '@hooks/useUrl';
import apiOms from '@utils/apiOms';
import type { BRANDS } from '@utils/brand';
import type { Notifications } from 'types/apiOms';

type FormValues = {
    notifications: {
        email?: boolean | null;
        postal?: boolean | null;
        petitSezane?: boolean | null;
        composantes?: boolean | null;
    };
    sms?: boolean | null;
    otherBrand?: boolean | null;
};

// TODO This should be dynamic / documented by API / configurable
type MarketingBrandCode = 'bendabili' | 'lescomposantes' | 'octobre' | 'petitsezane' | 'sezane';

const checkNotificationForBrand = (
    brand: MarketingBrandCode,
    type: keyof Notifications,
    notifications: Notifications
) => {
    return notifications[type]?.find(notification => notification.marketingBrandCode === brand)?.status;
};

const brandGlobalNotifications = defineMessages({
    octobre: { id: 'notifications.field.globalNotifications.octobre' },
    sezane: { id: 'notifications.field.globalNotifications.sezane' },
});
const brandSMSNotifications = defineMessages({
    octobre: { id: 'notifications.field.sms.octobre' },
    sezane: { id: 'notifications.field.sms.sezane' },
});
const brandOtherBrandNotification = defineMessages({
    sezane: { id: 'notifications.field.otherBrand.sezane' },
    octobre: { id: 'notifications.field.otherBrand.octobre' },
});

const transformApiToForm = (notifications: Notifications, brand: BRANDS): FormValues => {
    const newsletterBrand = checkNotificationForBrand(brand, 'newsletter', notifications);
    const postalBrand = checkNotificationForBrand(brand, 'postal_mail', notifications);
    const newsletterPetitSez = checkNotificationForBrand('petitsezane', 'newsletter', notifications);
    const newsletterComposantes = checkNotificationForBrand('lescomposantes', 'newsletter', notifications);

    return {
        notifications: {
            email: newsletterBrand,
            postal: postalBrand,
            petitSezane: newsletterPetitSez,
            composantes: newsletterComposantes,
        },
        sms: checkNotificationForBrand(brand, 'sms', notifications),
        otherBrand: checkNotificationForBrand(brand === 'sezane' ? 'octobre' : 'sezane', 'newsletter', notifications),
    };
};

const transformFormToApi = (formValues: FormValues, brand: BRANDS): Notifications => {
    const newsletterValues = [];
    if (formValues.notifications.email !== undefined) {
        newsletterValues.push({ marketingBrandCode: brand, status: formValues.notifications.email });
    }
    if (formValues.otherBrand !== undefined) {
        newsletterValues.push({
            marketingBrandCode: brand === 'sezane' ? 'octobre' : 'sezane',
            status: formValues.otherBrand,
        });
    }
    if (formValues.notifications.petitSezane !== undefined) {
        newsletterValues.push({ marketingBrandCode: 'petitsezane', status: formValues.notifications.petitSezane });
    }

    if (formValues.notifications.composantes !== undefined) {
        newsletterValues.push({ marketingBrandCode: 'lescomposantes', status: formValues.notifications.composantes });
    }

    return {
        newsletter: newsletterValues,
        postal_mail:
            formValues.notifications.postal !== undefined
                ? [{ marketingBrandCode: brand, status: formValues.notifications.postal }]
                : [],
        sms: formValues.sms !== undefined ? [{ marketingBrandCode: brand, status: formValues.sms }] : [],
    };
};

const MyInvitations = () => {
    const { urls } = useUrl();
    const { brandCode, locale, site } = useAppContext();
    const intl = useIntl();

    const hasSMSConsent = site.code === 'us';

    const {
        formState: { isDirty, isSubmitting },
        handleSubmit,
        setError,
        reset,
        watch,
        setValue,
    } = useForm<FormValues>();

    const globalNotifications =
        watch('notifications.email') ||
        watch('notifications.postal') ||
        watch('notifications.petitSezane') ||
        watch('notifications.composantes');

    const { data: notifications } = useQuery(['notifications'], () =>
        apiOms.omsAuthenticated.notificationsGet({ brandCode, localeCode: locale, siteCode: site.code! })
    );

    const { mutateAsync: updateNotifications } = useMutation(
        (dataToUpdate: FormValues) =>
            apiOms.omsAuthenticated.notificationsUpdate(
                { brandCode, localeCode: locale, siteCode: site.code! },
                transformFormToApi(dataToUpdate, brandCode)
            ),
        {
            onSuccess: () => {
                addToast(intl.formatMessage({ id: 'informations.form.update.success' }), 'success');
            },
            onError: ({ error }) => {
                error['invalid-params'].map((param: any) =>
                    setError(param.name, { type: error.type, message: param.reason }, { shouldFocus: true })
                );
            },
        }
    );

    const onGlobalNotificationsChange = (value?: boolean) => {
        setValue('notifications.email', value, { shouldDirty: true });
        setValue('notifications.postal', value, { shouldDirty: true });
        setValue('notifications.petitSezane', value, { shouldDirty: true });
        setValue('notifications.composantes', value, { shouldDirty: true });
    };

    const onSubmit = (data: FormValues) => {
        return updateNotifications(data);
    };

    useEffect(() => {
        if (notifications?.data) {
            reset(transformApiToForm(notifications?.data, brandCode));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notifications?.data]);

    return (
        <div className="border-t border-gray-lighter pt-10">
            <SecondTitle>
                <FormattedMessage id="invitations.title" />
            </SecondTitle>
            <form className="my-10" onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-10 grid desktop:grid-cols-3">
                    <div className="col-span-2 space-y-10">
                        <BooleanRadio
                            value={globalNotifications}
                            onChange={onGlobalNotificationsChange}
                            labelStyles=""
                            label={intl.formatMessage(brandGlobalNotifications[brandCode])}
                            className="flex flex-row justify-between"
                        />
                        <Collapse
                            buttonLabel={<FormattedMessage id="invitations.collapse.title" />}
                            buttonClassName="w-full"
                            panelClassName="pl-10 grid gap-[1.6rem]"
                        >
                            <BooleanRadio
                                value={watch('notifications.email')}
                                onChange={value => setValue('notifications.email', value, { shouldDirty: true })}
                                labelStyles="text-sm"
                                label={intl.formatMessage({ id: 'notifications.field.email' })}
                                className="flex flex-row justify-between"
                            />
                            <BooleanRadio
                                value={watch('notifications.postal')}
                                onChange={value => setValue('notifications.postal', value, { shouldDirty: true })}
                                labelStyles="text-sm"
                                label={intl.formatMessage({ id: 'notifications.field.postal' })}
                                className="flex flex-row justify-between"
                            />
                            <BooleanRadio
                                value={watch('notifications.petitSezane')}
                                onChange={value => setValue('notifications.petitSezane', value, { shouldDirty: true })}
                                labelStyles="text-sm"
                                label={intl.formatMessage({ id: 'notifications.field.petitSezane' })}
                                className="flex flex-row justify-between"
                            />
                            {brandCode !== 'octobre' && (
                                <>
                                    <BooleanRadio
                                        value={watch('notifications.composantes')}
                                        onChange={value =>
                                            setValue('notifications.composantes', value, { shouldDirty: true })
                                        }
                                        labelStyles="text-sm"
                                        label={intl.formatMessage({ id: 'notifications.field.composantes' })}
                                        className="flex flex-row justify-between"
                                    />
                                    <p className="text-md italic text-gray-light">
                                        <FormattedMessage
                                            id="notifications.field.composantes.help"
                                            values={{
                                                a: chunks => (
                                                    <a
                                                        className="underline"
                                                        href={urls.privacyPolicy}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {chunks}
                                                    </a>
                                                ),
                                            }}
                                        />
                                    </p>
                                </>
                            )}
                            <hr />
                        </Collapse>
                        <div className="grid gap-[1.6rem]">
                            {hasSMSConsent && (
                                <BooleanRadio
                                    value={watch('sms')}
                                    onChange={value => setValue('sms', value, { shouldDirty: true })}
                                    labelStyles=""
                                    label={intl.formatMessage(brandSMSNotifications[brandCode])}
                                    className="flex flex-row justify-between"
                                />
                            )}
                            <BooleanRadio
                                value={watch('otherBrand')}
                                onChange={value => setValue('otherBrand', value, { shouldDirty: true })}
                                labelStyles=""
                                label={intl.formatMessage(brandOtherBrandNotification[brandCode])}
                                className="flex flex-row justify-between"
                            />
                        </div>
                    </div>
                </div>
                <Button disabled={!isDirty} type="submit" className="mt-10 desktop:w-[28rem]" isLoading={isSubmitting}>
                    <FormattedMessage id="informations.validate" />
                </Button>
            </form>
        </div>
    );
};

export default MyInvitations;
